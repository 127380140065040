import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`An order summary displays the estimated financial costs and configuration details of a service or offering.`}</p>
    </PageDescription>
    <h4 {...{
      "id": "status"
    }}>{`Status:`}</h4>
    <p><a parentName="p" {...{
        "href": "/experimental/overview"
      }}>{`Experimental`}</a></p>
    <h4 {...{
      "id": "maintainer"
    }}>{`Maintainer:`}</h4>
    <p><a parentName="p" {...{
        "href": "https://www.github.com/adrianamorales"
      }}>{`Adriana Morales`}</a></p>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">General guidelines</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Anatomy</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Examples</AnchorLink>
    </AnchorLinks>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1120px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "66.42857142857143%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsSAAALEgHS3X78AAABqElEQVQoz52QTUvCcBzHd+rSCwg86db8b27lEqaHnl6Dx1YT8Q3YC6kgUA/r5i3P3ToERS+gJw2DCBRzc+5JBbf20M8pUZgQfRh//mP7fH8PmKqqZ5JULpdLpdL0rFQqkiTBWa1Wa7XaeQhcstksQijBMAQe4/jt5ssrFgTB2HbG9sfYth3HgVff94PfKBQKBEFQFEXTifU1tl5/wjzP68h6V7U6XbnVapmm6bruOMT+BuSKosiybDqdJkkSEhqNBua6Xk815O77cDgwDKPf7+u6bsxhWdbBgUjTNM/zbEgoe/5bR2u32/oETZblXq+nzgHfRDGH43g8HofJOY6bVe7rlqZpkA0V4D8ors0B4+RyOTAZhqEoxLLMRIaZoeFhCMQvkiE3n89Dtzyf5jZSXJKbyYOQqawoivobsAdYWDQapWlmlaTQdGFfMgC9aQswTUsQ9iORCEIUTpBxBPLzD3m4mNFoJAhCNBZLIIIlVzLJ2HP9ERbmWn8AoovFw52tTGp3bylzvLx5etdUJpWdv3F//3B7c3Vx3Ty6DE4ufWUQYMF/cMPH/wSlD1mbKtYdYgAAAABJRU5ErkJggg==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of an order summary",
        "title": "Example of an order summary",
        "src": "/static/0664eeced526f4d6d917983e871b0032/026f9/summary-hero.png",
        "srcSet": ["/static/0664eeced526f4d6d917983e871b0032/d6747/summary-hero.png 288w", "/static/0664eeced526f4d6d917983e871b0032/09548/summary-hero.png 576w", "/static/0664eeced526f4d6d917983e871b0032/026f9/summary-hero.png 1120w"],
        "sizes": "(max-width: 1120px) 100vw, 1120px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">Example of an order summary</Caption>
    <h2 {...{
      "id": "general-guidelines"
    }}>{`General guidelines`}</h2>
    <p>{`On a catalog details page, an order summary appears when a user is ordering a new service and configuring its details. Depending on the service, the summary component can show different content such as the details of a promo code or itemized costs of a service.`}</p>
    <p>{`The summary highlights the default choices when a user first lands on the page, and updates the choices made as they customize the service. The choices are shown with a title and a subtitle.`}</p>
    <p>{`If a user navigates away from the page, the summary should remain populated with the user’s latest changes if they choose to return at a later time.`}</p>
    <h2 {...{
      "id": "anatomy"
    }}>{`Anatomy`}</h2>
    <h4 {...{
      "id": "placement"
    }}>{`Placement`}</h4>
    <p>{`The summary component is a grid influencer and stays fixed on the right side of the page, just below the global navigation bar.`}</p>
    <h4 {...{
      "id": "title"
    }}>{`Title`}</h4>
    <p>{`The title should only contain the word “Summary”. Please do not introduce new terminology or labels in the title area.`}</p>
    <h4 {...{
      "id": "content-area"
    }}>{`Content area`}</h4>
    <p>{`Depending on the service, the summary component can include content such as an itemized list of costs for a service or a promo code field.`}</p>
    <h4 {...{
      "id": "pricing-and-currency"
    }}>{`Pricing and currency`}</h4>
    <p>{`The itemized list of costs should all be presented in the same currency (Euros, U.S. dollars, etc.).`}</p>
    <h4 {...{
      "id": "terms-and-conditions"
    }}>{`Terms and conditions`}</h4>
    <p>{`When a user is creating a new service, they need to read and agree to the `}<strong parentName="p">{`Terms and Conditions`}</strong>{` agreement. The create button is disabled until they check the box.`}</p>
    <h4 {...{
      "id": "buttons"
    }}>{`Buttons`}</h4>
    <p>{`There are two buttons, one required primary button “Create” and one optional secondary button, which can say “Save”, “Clear” or “Save as template” depending on the use case. The buttons always stick to the bottom of the page and lie above the fold.`}</p>
    <p>{`If your service requires a reset or clear button in the summary component, be sure to include error checking and notify the user they will lose their changes.`}</p>
    <p><strong parentName="p">{`Note: These button labels are still being explored and tested.`}</strong></p>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1120px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "66.42857142857143%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsSAAALEgHS3X78AAAByUlEQVQoz4WSy2rbQBSGveumD9Bk5UiRJUu+VGlRTQslr9Bssqwv2C+Q9B0SgsEG04VxFkZ9gu4aujB9hFwqJZTsY69qWxppNDPqmZEi5FDIzyAOaL7zn/8whcViMRp9HQ6Hg8Hg8cuLfr8/Ho9t255Op5PJuW1/+3RwoGmaYRi7smRa+3d/7gvxc6Isnv9FUPR6PVmWy+Wyrhv1WtVxfnPY87zlcrlae6s18lGAMY5yCkLsB0FESKvVqlQqlmWVSiVo4LpugbGY//YR5gqQ7/k+XA7CMEy7YEyiiFHabrfBFuCakOM4HAYQbhMSoZB4KKKUEBzh1SrlRQtKaafTkSRZVVVIbppmCqMgRDCY8IAD4jVCaZGDFUUxDF1TVZjfgbEhMzgghJIJs2k3cgu42+3yzO8a5t6b1BlgnjXgewIyCwxA3pkxBpmLxaJuVBVVh5W7YmyWtQfXBBPL4wHAMOkFHs1mc3trCwLLu4qqpdtm+fEyz1AI4KSXgD/v7EiGplSVV1Zdcp2bDTgxzEZ9kvno+MuHxtvXHw9fWCcv359d3j3wzPRRJCKJbSKaE3hcXl3/mv38Prs9/RGfXbD5Mn7+ef73yYrD/gG0LWfuRqzEcwAAAABJRU5ErkJggg==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "kubernetes example",
        "title": "kubernetes example",
        "src": "/static/71b104c17513c17c000228ec01b64613/026f9/kubernetes-example.png",
        "srcSet": ["/static/71b104c17513c17c000228ec01b64613/d6747/kubernetes-example.png 288w", "/static/71b104c17513c17c000228ec01b64613/09548/kubernetes-example.png 576w", "/static/71b104c17513c17c000228ec01b64613/026f9/kubernetes-example.png 1120w"],
        "sizes": "(max-width: 1120px) 100vw, 1120px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <p><strong parentName="p">{`Last updated:`}</strong>{` Aug 9, 2019`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      